.video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background-color: #000;

  > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.caption {
  background-color: #000;
  color: white;
  margin: 0;
  padding: 10px;
  font-size: 1.4rem;
}
