.hero {
    position: relative;
    overflow: hidden;
    height: 300px;
    background: #333 url('../images/app/hero.jpg') no-repeat center center;
    background-size: cover;

    img {
        margin: 0 auto;
        display: block;
    }

    &::after {
        content: '';
        display: block;
        height: 300px;
        width: 900px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: radial-gradient(closest-side, rgba(0,0,0,0.5), rgba(0,0,0,0));
    }
}

.hero__logo {
    width: 300px;
    max-width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

}

.hero__banner {
    object-fit: cover;
}
