html {
  font-size: 62.5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                Helvetica, Arial, sans-serif;
  font-size: 2rem;
  line-height: 1.33;
  color: #333;
}

p {
  margin: .75em 0;
}

.lede {
  font-size: 2.3rem;
  margin-top: 1em;

  &:last-of-type {
    margin-bottom: 1.5em;
  }

  @media (min-width: 600px) {
    font-size: 2.6rem;
  }
}

h1,
h2,
h3 {
  font-family: 'Pangolin', sans-serif;
  margin: 0 0 1em;
}

h1 {
  font-size: 6rem;
  margin-bottom: 0;

  @media (min-width: 600px) {
    font-size: 8rem;
  }
}

h2 {
  font-size: 3rem;
  margin-bottom: .5em;
}

h3 {
  font-size: 2.2rem;
  margin: 0 0 .5em;
}

a {
  color: #005ea5;   // I've been working on GOV.UK too long!
  text-decoration: none;
  border-bottom: 1px solid #8c8c8c;

  &:hover,
  &:active {
    text-decoration: none;
    border-bottom: none;
  }
}

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.list-bullet {
  padding-left: 2em;

  li{
    list-style-type: disc;
  }
}
